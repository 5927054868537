import React from "react"
import styled from "styled-components"

const CreditsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  align-self: center;
  flex-shrink: 0;

  @media (max-width: 700px) {
    width: calc(100% - 40px);
    justify-content: center;
    margin: 20px 0 0 0;
  }
`

const CreditTitle = styled.h4`
  font-family: "Barlow";
  font-weight: 400;
  font-size: 12px;
  color: white;
  margin: 0 40px 0 0;

  @media (max-width: 700px) {
    margin: 0 20px 0 0;
    font-size: 12px;
  }

  @media (max-width: 500px) {
    margin: 0 10px 0 0;
  }
`

const CreditsList = styled.div`
  display: flex;
  flex-direction: column;
`

const Credit = styled.p`
  font-family: "Barlow";
  font-weight: 400;
  font-size: 12px;
  color: white;
  margin: 0 0 10px 0;
  width: 100%;

  @media (max-width: 700px) {
    margin: 0 0 5px 0;
    font-size: 12px;
  }

  @media (max-width: 500px) {
    margin: 0 0 5px 0;
  }
`

const CreditSubTitle = styled.span`
  color: #4f4f4f;
`

const ProjectCredits = ({ credits }) => {
  const creditsContent =
    credits &&
    credits.map((credit, i) => {
      if (credit.label !== "") {
        return (
          <Credit key={`credit-${credit.label}-${i}`}>
            <CreditSubTitle>{credit.label}: </CreditSubTitle>
            {credit.name}
          </Credit>
        )
      } else {
        return <Credit>{credit.name}</Credit>
      }
    })
  return (
    <>
      {credits &&
      credits.length !== 0 &&
      credits[0].label !== "Studio" &&
      credits[0].name !== "Andras" ? (
        <CreditsContainer>
          <CreditTitle>Credits</CreditTitle>
          <CreditsList>{creditsContent}</CreditsList>
        </CreditsContainer>
      ) : (
        <></>
      )}
    </>
  )
}

export default ProjectCredits
