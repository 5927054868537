import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const FullWidthVideo = styled.video`
  width: 100%;
`

function renderVideo(file) {
  return (
    <FullWidthVideo loop muted autoPlay playsInline src={file.node.publicURL} />
  )
}

const Video = ({ videoRef, videoPath }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          videos: allFile(filter: { extension: { regex: "/mp4/" } }) {
            edges {
              node {
                extension
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={({ videos }) =>
        renderVideo(
          videos.edges.find(
            video =>
              video.node.relativePath === `projects/${videoRef}/${videoPath}`
          )
        )
      }
    />
  )
}

export default Video
