import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

function renderImage(file) {
  return <StyledImage alt="filler" fluid={file.node.childImageSharp.fluid} />
}

const Image = ({ imageRef, imagePath, alt }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1400, quality: 60) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find(
            image =>
              image.node.relativePath === `projects/${imageRef}/${imagePath}`
          )
        )
      }
    />
  )
}

export default Image
