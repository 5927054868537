import React from "react"
import Topbar from "../components/topbar"
import SEO from "../components/seo"
import { TransitionProvider } from "../contexts/transitionContext"
import { LoadingProvider } from "../contexts/loadingContext"
import BottomBar from "../components/bottombar"
import Loader from "../components/loader"
import GlobalStyleComponent from "../components/globalStyle"

const Layout = ({ children, path, location }) => {
  return (
    <TransitionProvider>
      <LoadingProvider>
        <SEO />
        <GlobalStyleComponent />
        <Loader path={path} />
        {location.pathname !== "/" ? <Topbar path={path} /> : <></>}
        {children}
        {location.pathname !== "/" ? <BottomBar path={path} /> : <></>}
      </LoadingProvider>
    </TransitionProvider>
  )
}

export default Layout
