import React, { useEffect } from "react"
import { gsap } from "gsap"
import styled from "styled-components"
import BottomBar from "../components/bottombar"
// import LogoVideo from "../images/info-video.mp4"
import InfoAnimation from "../images/info-animation.gif"

const ContentOverlay = styled.div`
  background: black;
  position: fixed;
  pointer-events: none;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
`

const FillerDiv = styled.div`
  width: 100%;
  height: calc(100vh - 147.2px - 100px);
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    height: fit-content;
    padding-top: 120px;
    padding-bottom: 30px;
  }
`

const CopyContainer = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-gap: 80px;

  @media (max-width: 1000px) {
    width: 70%;
  }

  @media (max-width: 900px) {
    width: calc(100% - 100px);
  }

  @media (max-width: 700px) {
    width: calc(100% - 40px);
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1400px) {
    width: 60%;
  }
`

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 700px) {
    width: 100%;
  }

  @media (min-width: 1400px) {
    max-width: 500px;
  }
`

const InfoCopy = styled.div`
  width: 100%;
  color: white;
  font-size: 14px;
  font-family: Barlow;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

const ServicesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const ServicesCopy = styled.div`
  width: 50%;
  color: white;
  margin-top: 25px;
  font-family: Barlow;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

const ServiceHeading = styled.p`
  color: #4f4f4f;
  font-family: Barlow;
  font-weight: 400;
  margin: 0 0 5px 0;
`

const Service = styled.p`
  color: white;
  font-family: Barlow;
  margin: 0;
`

const ContactContainer = styled.div`
  width: 100%;
  justify-self: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Email = styled.a`
  /* align-self: center;
  justify-self: flex-start; */
  color: white;
  font-family: Barlow;
  font-size: 16px;
  text-transform: lowercase;
  margin: 40px 0 0 0;
  @media (max-width: 700px) {
    align-self: start;
    font-size: 16px;
  }
`

const InfoVideo = styled.img`
  width: 100%;

  @media (max-width: 700px) {
    width: 70%;
  }
`

const Info = () => {
  useEffect(() => {
    gsap.to("#infoOverlay", {
      duration: 1,
      ease: "none",
      opacity: 0,
      delay: 0.5,
    })
  }, [])

  return (
    <>
      <ContentOverlay id="infoOverlay"></ContentOverlay>
      <FillerDiv>
        <CopyContainer>
          <ContactContainer>
            {/* <Email href="mailto:contact@andrasii.com">
              contact@andrasii.com
            </Email> */}
            <InfoVideo src={InfoAnimation}></InfoVideo>
          </ContactContainer>
          <InfoContainer>
            <InfoCopy>
              Working with people from around the world to shapeshift our
              creative and to find the joy of creative collaboration.
            </InfoCopy>
            <ServicesContainer>
              <ServicesCopy>
                <ServiceHeading>FILM</ServiceHeading>
                <Service>COMMERCIALS</Service>
                <Service>BRANDED CONTENT</Service>
                <Service>DOCUMENTARY</Service>
                <Service>MUSIC</Service>
              </ServicesCopy>
              <ServicesCopy>
                <ServiceHeading>DESIGN</ServiceHeading>
                <Service>CREATIVE DIRECTION</Service>
                <Service>2D/3D ANIMATION</Service>
                <Service>GRAPHIC DESIGN</Service>
                <Service>ILLUSTRATION</Service>
              </ServicesCopy>
            </ServicesContainer>
            <Email href="mailto:contact@andrasii.com">
              contact@andrasii.com
            </Email>
          </InfoContainer>
        </CopyContainer>
      </FillerDiv>
    </>
  )
}

export default Info
