import React, { useState, useContext } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { TransitionContext } from "../contexts/transitionContext"
import AndrasLogo from "../images/ANDRAS-LOGO-WHT.png"
import IGLogo from "../images/ig.png"
import TwitterLogo from "../images/twitter.png"
import VimeoLogo from "../images/vimeo.png"
import YoutubeLogo from "../images/youtube.png"

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 80px 50px 20px 50px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 30px;
  left: 0;
  color: white;

  @media (max-width: 700px) {
    padding: 50px 20px 20px 20px;
    flex-direction: column;
  }
`

const LocationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 190px;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
  }
`

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    display: none;
  }
`

const ImgContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  @media (min-width: 700px) {
    display: none;
  }
`

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 190px;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: space-between;
  }
`

const ContactSocialLink = styled.a`
  text-decoration: none;
`

const Logo = styled.img`
  width: 70px;
  cursor: pointer;

  @media (max-width: 700px) {
    width: 60px;
  }
`

const ContactSocialImg = styled.img`
  width: 20px;
  margin: 0 20px 0 0;
  @media (max-width: 700px) {
    margin: 0 10px 0 10px;
  }
`
const TimeContainer = styled.div`
  font-family: Barlow;
  color: white;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  @media (max-width: 800px) {
    margin: 0 10px 0 10px;
  }
`

const Time = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`

const TimeLabel = styled.div`
  font-size: 12px;
  text-align: center;
  color: #4f4f4f;
  white-space: nowrap;
`

const BottomBar = ({ path }) => {
  const { handleForwardLink, handleBackLink } = useContext(TransitionContext)
  const [NYTime, setNYTime] = useState()
  const [LATime, setLATime] = useState()
  const [SeoulTime, setSeoulTime] = useState()

  const Toronto = new Intl.DateTimeFormat([], {
    timeZone: "America/New_York",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  })

  const LosAngeles = new Intl.DateTimeFormat([], {
    timeZone: "America/Los_Angeles",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  })

  const Seoul = new Intl.DateTimeFormat([], {
    timeZone: "Asia/Seoul",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  })

  setInterval(() => {
    setNYTime(Toronto.format(new Date()))
    setLATime(LosAngeles.format(new Date()))
    setSeoulTime(Seoul.format(new Date()))
  }, 1000)

  const projectClickHandler = () => {
    if (path !== "/projects/" && path !== "/projects" && path !== "/") {
      if (path !== "/info/" && path !== "/info" && path !== "/") {
        handleBackLink(".projectOverlay", "/projects", "project")
      } else {
        handleBackLink("#infoOverlay", "/projects", "info")
      }
    } else {
      navigate("/projects")
    }
  }

  return (
    <Container>
      <ImgContainerMobile>
        <Logo onClick={projectClickHandler} src={AndrasLogo}></Logo>
      </ImgContainerMobile>
      <SocialContainer>
        <ContactSocialLink
          target="_blank"
          href="https://www.instagram.com/andras.ii/"
        >
          <ContactSocialImg src={IGLogo} />
        </ContactSocialLink>
        <ContactSocialLink
          target="_blank"
          href="https://twitter.com/ANDRAS_ii/"
        >
          <ContactSocialImg src={TwitterLogo} />
        </ContactSocialLink>
        <ContactSocialLink
          target="_blank"
          href="https://vimeo.com/andrasgroup/"
        >
          <ContactSocialImg src={VimeoLogo} />
        </ContactSocialLink>
        <ContactSocialLink
          target="_blank"
          href="https://www.youtube.com/channel/UCPwuKAvbuJRp1BQZF_p2xig?view_as=subscriber"
        >
          <ContactSocialImg src={YoutubeLogo} />
        </ContactSocialLink>
      </SocialContainer>

      <ImgContainer>
        <Logo onClick={projectClickHandler} src={AndrasLogo}></Logo>
      </ImgContainer>

      <LocationsContainer>
        <TimeContainer>
          <Time>{NYTime}</Time>
          <TimeLabel>TORONTO</TimeLabel>
        </TimeContainer>
        <TimeContainer>
          <Time>{LATime}</Time>
          <TimeLabel>LOS ANGELES</TimeLabel>
        </TimeContainer>
        <TimeContainer>
          <Time>{SeoulTime}</Time>
          <TimeLabel>SEOUL</TimeLabel>
        </TimeContainer>
      </LocationsContainer>
    </Container>
  )
}

export default BottomBar
