import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { graphql } from "gatsby"
import Image from "../../components/image.js"
import Gif from "../../components/gif.js"
import { TransitionContext } from "../../contexts/transitionContext"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;
  z-index: 3;
`

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ProjectsGrid = styled.div`
  height: 100%;
  width: calc(100% - 100px);
  margin: 150px auto 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 6px 10px;
  opacity: 0;

  grid-template-areas:
    /* "large1 large1 small1 medium1"
    "large1 large1 small2 medium1"
    "small3 small4 medium2 medium2"
    "small5 small6 large2 large2"
    "medium3 medium3 large2 large2"
    "small7 small8 small9 small10"; */
    /* "large1 large1 small1 medium1"
    "large1 large1 small2 medium1"
    "small3 medium2 medium3 medium3"
    "small4 medium2 small5 small6"
    "medium4 medium4 large2 large2"
    "small7 small8 large2 large2"
    "medium5 medium5 small9 small10" */
    "large1 large1 medium1 medium2"
    "large1 large1 medium1 medium2"
    "small1 medium3 small2 small3"
    "small4 medium3 medium4 medium4"
    "small5 small6 small7 small8"
    "large2 large2 medium5 medium6"
    "large2 large2 medium5 medium6";

  @media (max-width: 1000px) {
    margin: 120px auto 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      /* "large1 large1"
      "small1 small2"
      "medium1 small3"
      "medium1 small4"
      "medium2 medium2"
      "small5 large2"
      "small6 large2"
      "medium3 medium3"
      "small7 small8"
      "medium4 medium4"
      "medium5 medium5"
      "small9 small10"; */
      "large1 large1"
      "small1 small2"
      "medium1 small3"
      "medium1 small4"
      "small5 medium2"
      "small6 medium2"
      "medium3 medium6"
      "medium3 medium6"
      "medium4 medium4"
      "large2 large2"
      "small7 small8"
      "medium5 medium5";
  }

  @media (max-width: 700px) {
    width: calc(100% - 40px);
  }
`

const ProjectLink = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  transition: opacity 0.1s ease;

  &:hover {
    opacity: 0.7;
  }

  ${({ gridArea }) => gridArea && `grid-area: ${gridArea}`}
`

const ProjectTitle = styled.h4`
  font-family: "Barlow";
  color: white;
  font-weight: 400;
  font-size: 12px;
  margin: 5px 0 0 0;
  text-transform: none;
  text-align: right;

  @media (max-width: 700px) {
    font-size: 10px;
  }
`

const ProjectsPage = ({ location, data }) => {
  const { handleForwardLink, setOverlay, moveOverlay } = useContext(
    TransitionContext
  )

  useEffect(() => {
    if (location.state && location.state.fromProject === true) {
      setOverlay(".infoOverlay", "100%")
      moveOverlay("#projectsOverlay", "-100%")
    } else if (location.state && location.state.fromInfo === true) {
      setOverlay("#projectsOverlay", "-100%")
      moveOverlay(".infoOverlay", "100%")
    } else {
      setOverlay("#projectsOverlay", "-100%")
      setOverlay(".infoOverlay", "100%")
    }
  }, [location.state, moveOverlay, setOverlay])

  useEffect(() => {
    gsap.to(["#LogoContainer", "#ProjectsGrid"], { duration: 0.75, opacity: 1 })
  }, [])

  const projects = data.allSitePage.edges.map(edge => {
    const node = edge.node.context
    return (
      <ProjectLink
        gridArea={node.gridArea}
        key={`project-${node.slug}`}
        onClick={() => {
          handleForwardLink("#projectsOverlay", `/projects/${node.slug}`)
        }}
      >
        {node.mainContent.contentType === "image" ? (
          <Image
            alt="filler"
            imageRef={node.imageRef}
            imagePath={node.mainContent.contentPath}
          />
        ) : (
          <Gif gifRef={node.imageRef} gifPath={node.mainContent.contentPath} />
        )}
        <ProjectTitle>{node.title}</ProjectTitle>
      </ProjectLink>
    )
  })

  return (
    <>
      <Overlay id="projectsOverlay"></Overlay>
      <Overlay className="infoOverlay"></Overlay>
      <ProjectContainer>
        <ProjectsGrid id="ProjectsGrid">{projects}</ProjectsGrid>
      </ProjectContainer>
    </>
  )
}

export default ProjectsPage

export const query = graphql`
  query {
    allSitePage(
      filter: { isCreatedByStatefulCreatePages: { eq: false }, context: {} }
    ) {
      edges {
        node {
          context {
            imageRef
            gridArea
            slug
            title
            mainContent {
              contentPath
              contentType
            }
          }
        }
      }
    }
  }
`
