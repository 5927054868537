import React, { createContext } from "react"
import { gsap } from "gsap"
import { navigate } from "gatsby"

export const TransitionContext = createContext()

export function TransitionProvider(props) {
  const handleForwardLink = (overlay, page) => {
    gsap.to(`${overlay}`, {
      duration: 0.5,
      ease: "none",
      x: 0,
      onComplete: () => {
        navigate(page)
      },
    })
  }

  const handleBackLink = (overlay, page, type) => {
    let transitionState = { fromProject: true }
    if (type === "info") {
      transitionState = { fromInfo: true }
    }
    gsap.to(`${overlay}`, {
      duration: 0.5,
      ease: "none",
      opacity: 1,
      onComplete: () => {
        navigate(`${page}`, {
          state: transitionState,
        })
      },
    })
  }

  const setOverlay = (overlay, position) => {
    gsap.set(`${overlay}`, {
      x: position,
    })
  }

  const moveOverlay = (overlay, position) => {
    gsap.to(`${overlay}`, {
      duration: 0.5,
      delay: 0.25,
      x: position,
      ease: "none",
    })
  }

  return (
    <TransitionContext.Provider
      value={{
        handleForwardLink,
        handleBackLink,
        setOverlay,
        moveOverlay,
      }}
    >
      {props.children}
    </TransitionContext.Provider>
  )
}
