import React, { createContext, useEffect, useState } from "react"

export const LoadingContext = createContext()

export function LoadingProvider(props) {
  const [loaderVisible, setLoaderVisible] = useState(true)
  const [disableScroll, setDisableScroll] = useState(true)

  const completeLandingAnimation = () => {
    setLoaderVisible(false)
    setDisableScroll(false)
  }

  return (
    <LoadingContext.Provider
      value={{
        loaderVisible,
        completeLandingAnimation,
        disableScroll,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  )
}
