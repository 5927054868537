import React, {
  useRef,
  Suspense,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react"
import { Canvas, useFrame, useThree, extend } from "react-three-fiber"

import { Vector2 } from "three"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { Link } from "gatsby"
import styled from "styled-components"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { Html, useGLTF, useCubeTexture } from "@react-three/drei/"
import * as THREE from "three"
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js"
import InteractLogo from "../images/ANDRAS_interactlogo_V2.png"
import { LoadingContext } from "../contexts/loadingContext"

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  UnrealBloomPass,
  OrbitControls,
})

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "acumin-pro-condensed";
  font-weight: 900;
  color: white;
  font-size: 22px;
  text-decoration: none;
  img {
    width: 10vw;

    @media (min-width: 1800px) {
      width: 6vw;
    }

    @media (max-width: 1500px) {
      width: 7vw;
    }

    @media (max-width: 1250px) {
      width: 9vw;
    }

    @media (max-width: 1000px) {
      width: 10vw;
    }

    @media (max-width: 730px) {
      width: 13vw;
    }

    @media (max-width: 610px) {
      width: 21.5vw;
    }

    @media (max-width: 400px) {
      width: 29vw;
    }
  }
`

var path = "textures/newHDRICompressedjpgs/"
var format = "-min.jpg"
var urls = [
  "px" + format,
  "nx" + format,
  "py" + format,
  "ny" + format,
  "pz" + format,
  "nz" + format,
]

// useGLTF.preload("../models/modelDraco.gltf")
// useCubeTexture.preload(urls, { path: path })
const Asset = ({
  url,
  setRedirect,
  changeText,
  reveal,
  completeLandingAnimation,
}) => {
  const gltf = useGLTF(url)
  const envMap = useCubeTexture(urls, { path: path })
  const prim = useRef()
  const [position, setPosition] = useState([0, 0, -5])
  const [intensity, setIntensity] = useState(0.1)
  const { scene } = useThree()
  const { gl } = useThree()
  const { mouse } = useThree()
  const [lightOn, setLight] = useState(false)

  useEffect(() => {
    const envMapIntensity = 0.1
    const roughness = 0
    const metalness = 1
    const material = gltf.materials[""]
    envMap.encoding = THREE.sRGBEncoding
    envMap.minFilter = THREE.LinearFilter
    envMap.magFilter = THREE.LinearFilter
    envMap.anisotropy = gl.capabilities.getMaxAnisotropy()
    const pmremGenerator = new THREE.PMREMGenerator(gl)
    pmremGenerator.compileCubemapShader()
    let renderTarget = pmremGenerator.fromCubemap(envMap)
    material.roughness = roughness
    material.metalness = metalness
    material.envMap = envMap
    material.envMapIntensity = envMapIntensity

    pmremGenerator.dispose()

    completeLandingAnimation()
  }, [])

  useFrame(() => {
    gltf.materials[""].envMapIntensity = intensity
    if (reveal) {
      prim.current.rotation.x += (0 - prim.current.rotation.x) * 0.03
      prim.current.rotation.y += (0 - prim.current.rotation.y) * 0.03
    } else {
      prim.current.rotation.x +=
        (mouse.y * 0.1 - prim.current.rotation.x) * 0.05
      prim.current.rotation.y +=
        (-mouse.x * 0.1 - prim.current.rotation.y) * 0.05
    }
  })

  useEffect(() => {
    if (reveal) {
      setIntensity(1)
    }
  }, [reveal])

  return (
    <>
      <primitive
        object={gltf.scene}
        dispose={null}
        scale={[0.01, -0.01, 0.01]}
        position={position}
        ref={prim}
      />
      <mesh
        position={[0, 0.5, -5]}
        onPointerEnter={() => {
          setLight(true)
        }}
        onPointerOut={() => {
          setLight(false)
          setIntensity(0.1)
        }}
        // on hover enter andras then light it up bare
        onPointerMove={e => {
          const raycastPoint = e.point
          e.object.updateMatrixWorld()
          const distance = raycastPoint.distanceTo(
            e.object.getWorldPosition(new THREE.Vector3())
          )
          let intensity = THREE.MathUtils.mapLinear(distance, 0.7, 9, 1.0, 0.1)
          intensity = THREE.MathUtils.clamp(intensity, 0, 1)
          setIntensity(intensity)
        }}
      >
        <circleBufferGeometry args={[9]} />
        <meshBasicMaterial visible={false} color={"hotpink"} />
      </mesh>

      <Light
        xOffset={0}
        yOffset={0}
        setRedirect={setRedirect}
        changeText={changeText}
        lightOn={lightOn}
        intensity={intensity}
        centerPosition={position}
        reveal={reveal}
      />
    </>
  )
}

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement },
  } = useThree()

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef()
  useFrame(state => controls.current.update())
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      enableZoom={false}
      enabled={false}
      autoRotate
    />
  )
}
const Light = ({
  xOffset = 0,
  yOffset = 0,
  setRedirect,
  changeText,
  lightOn,
  intensity,
  centerPosition,
  reveal,
}) => {
  const ref = useRef()
  const directionalRef = useRef()

  const { mouse, size } = useThree()
  const lastTouchStartRef = useRef()
  useEffect(() => {
    // gsap.to(ref.current, { duration: 2, intensity: 0, delay: 0.75 })

    function handleTouchStart(e) {
      lastTouchStartRef.current = e.changedTouches[0]
      e.preventDefault()
      setTimeout(() => {
        changeText()
      }, 3000)
    }

    function handleTouchEnd(e) {
      e.preventDefault()
      const xDiff = Math.abs(
        e.changedTouches[0].screenX - lastTouchStartRef.current.screenX
      )
      const yDiff = Math.abs(
        e.changedTouches[0].screenY - lastTouchStartRef.current.screenY
      )

      if (xDiff <= 10 && yDiff <= 10) {
        setRedirect(true)
      }
    }
    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      window.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      })
      window.addEventListener("touchend", handleTouchEnd, { passive: false })

      return () => {
        window.removeEventListener("touchstart", handleTouchStart)
        window.removeEventListener("touchend", handleTouchEnd)
      }
    }
  }, [changeText, setRedirect])

  useFrame(() => {
    // if (
    //   "ontouchstart" in window ||
    //   navigator.maxTouchPoints > 0 ||
    //   navigator.msMaxTouchPoints > 0
    // ) {
    //   if (mouse.x <= -1 && mouse.y >= 1) {
    //   } else {
    //     ref.current.position.x +=
    //       (mouse.x * 10 + xOffset - ref.current.position.x) * 0.05
    //     ref.current.position.y +=
    //       (mouse.y * 10 + yOffset - ref.current.position.y) * 0.05
    //   }
    // } else {
    //   // ref.current.position.x = mouse.x * 10 + xOffset
    //   // ref.current.position.y = mouse.y * 10 + yOffset
    // }
    if (reveal) {
      ref.current.position.x += (0 - ref.current.position.x) * 0.05
      ref.current.position.y += (0 - ref.current.position.y) * 0.05
    } else {
      ref.current.position.x +=
        (mouse.x * 2.5 + xOffset - ref.current.position.x) * 0.05
      ref.current.position.y +=
        (mouse.y * 2.5 + yOffset - ref.current.position.y) * 0.05
    }
    if (size.width < 700) {
      ref.current.intensity = THREE.MathUtils.mapLinear(
        intensity,
        0.1,
        1,
        0,
        30
      )

      directionalRef.current.intensity = THREE.MathUtils.mapLinear(
        intensity,
        0.1,
        1,
        0,
        5
      )
    } else {
      ref.current.intensity = THREE.MathUtils.mapLinear(intensity, 0.1, 1, 0, 3)
      directionalRef.current.intensity = THREE.MathUtils.mapLinear(
        intensity,
        0.1,
        1,
        0,
        1
      )
    }

    directionalRef.current.position.copy(ref.current.position)
  })

  useEffect(() => {
    const target = new THREE.Object3D()
    target.position.set(...centerPosition)
    directionalRef.current.target = target
  }, [])

  // useEffect(() => {
  //   if (lightOn) {
  //     // gsap.to(ref.current, { duration: 0.5, intensity: 3, delay: 0 })
  //   } else {
  //     // gsap.to(ref.current, { duration: 0.5, intensity: 0, delay: 0 })
  //   }
  // }, [lightOn])
  return (
    <>
      {/* <ambientLight /> */}
      <pointLight
        position={[0, 0, 3]}
        color={"#FFFfFF"}
        intensity={0}
        ref={ref}
        distance={20}
      />
      {/* {ref.current && <pointLightHelper args={[ref.current, 5]} />} */}
      <directionalLight ref={directionalRef} intensity={0} />
    </>
  )
}

const Effects = () => {
  const composer = useRef()
  const aliasing = useRef()

  const { scene, gl, size, camera } = useThree()
  const aspect = useMemo(() => new Vector2(size.width, size.height), [size])
  useEffect(() => {
    // composer.current.reset()
    // aliasing.current.reset()
    composer.current.setSize(size.width, size.height)
    aliasing.current.material.uniforms.resolution.value.set(
      1 / size.width,
      1 / size.height
    )
  }, [size])
  useEffect(() => {
    // gl.toneMapping = THREE.ACESFilmicToneMapping
    gl.toneMappingExposure = 1.5
    // gl.physicallyCorrectLights = true
    // gl.toneMapping = ACESFilmicToneMapping
    // gl.outputEncoding = THREE.sRGBEncoding
    // if (aliasing.current) {
    //   var pixelRatio = gl.getPixelRatio()
    //   var uniforms = aliasing.current.material.uniforms
    //   uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio)
    //   uniforms["resolution"].value.y = 1 / (window.innerHeight * pixelRatio)
    // }
    gl.autoClear = true
  }, [])
  useFrame(() => composer.current.render(), 1)
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <unrealBloomPass attachArray="passes" args={[aspect, 0.4, 0.6, 0.6]} />
      <shaderPass
        ref={aliasing}
        attachArray="passes"
        args={[FXAAShader]}
        material-uniforms-resolution-value={[1 / size.width, 1 / size.height]}
        renderToScreen
      />
    </effectComposer>
  )
}

const LogoAnimation = ({ setRedirect }) => {
  const [devicePixelRatio, setDevicePixelRatio] = useState(0)
  const [reveal, setReveal] = useState(false)
  const [text, setText] = useState("ENTER ANDRAS")
  const [scenePos, setScenePos] = useState([0, 0, 0])
  const [textPosition, setTxtPosition] = useState([0, -5.5, -5])
  const textRef = useRef()
  const { loaderVisible, completeLandingAnimation } = useContext(LoadingContext)

  const changeText = () => {
    // gsap.to(textRef.current, {
    //   duration: 0.75,
    //   opacity: 0,
    //   onComplete: () => {
    //     // setText("ENTER ANDRAS")
    //     gsap.to(textRef.current, { duration: 0.75, opacity: 1 })
    //   },
    // })
  }

  useEffect(() => {
    function handleResize() {
      setDevicePixelRatio(window.devicePixelRatio)
      if (window.innerWidth < 700) {
        // setText("DRAG")
        setScenePos([0, 1, -5])
        // if (window.innerHeight < 700) {
        //   setTxtPosition([0, -5.5, -5])
        // } else {
        setTxtPosition([0, -8, -5])
        // }

        if (navigator.userAgent.match(/instagram/i)) {
          // if (window.innerHeight < 700) {
          //   setTxtPosition([0, -8, -5])
          // } else {
          setTxtPosition([0, -8, -5])
          // }
        }

        if (window.innerWidth < 330) {
          setScenePos([0, 1, -6.5])
        }
      } else {
        // setText("ENTER ANDRAS")
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
  }, [])

  return (
    <Canvas
      pixelRatio={devicePixelRatio}
      style={{
        height: "100%",
        background: "black",
        position: "fixed",
      }}
    >
      <group position={scenePos} matrixWorldNeedsUpdate>
        <Suspense fallback={<></>}>
          <Asset
            url="../models/model.glb"
            changeText={changeText}
            setRedirect={setRedirect}
            reveal={reveal}
            completeLandingAnimation={completeLandingAnimation}
          />
        </Suspense>
        <Effects />

        {/* <CameraControls /> */}
        <Html
          center={true}
          position={textPosition}
          style={{
            color: "white",
          }}
          ref={textRef}
        >
          <StyledLink to="/projects">
            <img
              src={InteractLogo}
              onPointerEnter={() => {
                setReveal(true)
              }}
              onPointerOut={() => {
                setReveal(false)
              }}
            />
          </StyledLink>
        </Html>
      </group>
    </Canvas>
  )
}

export default LogoAnimation
