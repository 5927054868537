import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { gsap } from "gsap"
import styled from "styled-components"
import ProjectBanner from "../components/project/banner.js"
import ProjectGallery from "../components/project/gallery.js"
import AltGalleryTwo from "../components/project/altGalleryTwo.js"
import { TransitionContext } from "../contexts/transitionContext"

const ContentOverlay = styled.div`
  background: black;
  position: fixed;
  pointer-events: none;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;
  z-index: 3;
`

const ProjectContainer = styled.main`
  width: 100%;
`

const Project = ({ data, location }) => {
  const { setOverlay, moveOverlay } = useContext(TransitionContext)

  useEffect(() => {
    gsap.to(".projectOverlay", {
      duration: 1,
      ease: "none",
      opacity: 0,
      delay: 0.5,
    })
  }, [])

  useEffect(() => {
    if (location.state && location.state.fromInfo === true) {
      moveOverlay(".infoOverlay", "100%")
    } else {
      setOverlay(".infoOverlay", "100%")
    }
  }, [location.state, moveOverlay, setOverlay])

  return (
    <>
      <Overlay className="infoOverlay"></Overlay>
      <ContentOverlay className="projectOverlay"></ContentOverlay>
      <ProjectContainer>
        {data.allSitePage.edges[0].node.context.alternatePage2 ? (
          // <AltGallery
          //   title={data.allSitePage.edges[0].node.context.title}
          //   videos={data.allSitePage.edges[0].node.context.alternateVideoLinks}
          // />
          <AltGalleryTwo
            title={data.allSitePage.edges[0].node.context.title}
            largeVideos={
              data.allSitePage.edges[0].node.context.alternateLargeVideoLinks
            }
            smallVideos={
              data.allSitePage.edges[0].node.context.alternateSmallVideoLinks
            }
          />
        ) : (
          <>
            <ProjectBanner
              title={data.allSitePage.edges[0].node.context.title}
              videoLink={data.allSitePage.edges[0].node.context.videoLink}
              height={data.allSitePage.edges[0].node.context.videoHeight}
              credits={data.allSitePage.edges[0].node.context.credits}
            ></ProjectBanner>
            <ProjectGallery
              content={data.allSitePage.edges[0].node.context.content}
              imageRef={data.allSitePage.edges[0].node.context.imageRef}
            />
          </>
        )}
      </ProjectContainer>
    </>
  )
}

export default Project

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            slug
            title
            videoLink
            videoHeight
            imageRef
            content {
              contentPath
              gridArea
              contentType
            }
            credits {
              label
              name
            }
            alternatePage2
            alternateLargeVideoLinks {
              video
            }
            alternateSmallVideoLinks {
              video
            }
          }
        }
      }
    }
  }
`
