import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import LogoAnimation from "../components/logoAnimation.js"
import gsap from "gsap"
import { navigate } from "gatsby"
import AndrasLogo from "../images/ANDRAS-LOGO-WHT.png"
const LogoContainer = styled.div`
  /* margin-top: 5px; */
  width: 100%;
  text-align: center;
`

const Logo = styled.img`
  width: 80px;
  z-index: 10;
  opacity: 0;
`
const RedirectContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`
const IndexPage = () => {
  const [redirectToProjects, setRedirect] = useState(false)
  const fadeThis = useRef()
  useEffect(() => {
    if (redirectToProjects) {
      gsap.to(fadeThis.current, {
        duration: 0.75,
        opacity: 0,
        onComplete: () => {
          navigate("/projects/")
        },
      })
    }
  }, [redirectToProjects])
  return (
    <>
      {/* <LogoContainer>
        <Logo src={AndrasLogo} />
      </LogoContainer> */}
      <RedirectContainer
        ref={fadeThis}
        onClick={() => {
          setRedirect(true)
        }}
      >
        <LogoAnimation setRedirect={setRedirect} />
      </RedirectContainer>
    </>
  )
}

export default IndexPage
