import React from "react"

const NotFoundPage = () => {
  return (
    <>
      <div></div>
    </>
  )
}

export default NotFoundPage
