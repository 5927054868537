import React from "react"
import styled from "styled-components"
import ProjectCredits from "./credits.js"

const Container = styled.div`
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  margin: 150px auto 0 auto;
  @media (max-width: 700px) {
    width: calc(100% - 40px);
    margin: 120px auto 0 auto;
  }
`

const Title = styled.h1`
  font-family: "Bebas Neue";
  font-weight: 900;
  font-size: ${props => (props.smaller ? "64px" : "84px")};
  color: white;
  width: fit-content;
  margin: ${props => (props.noMargin ? "0" : "0 80px 0 0")};
  align-self: center;
  text-transform: none;

  @media (max-width: 1200px) {
    margin: ${props => (props.noMargin ? "0" : "0 60px 0 0")};
    font-size: ${props => (props.smaller ? "56px" : "72px")};
  }

  @media (max-width: 1000px) {
    font-size: ${props => (props.smaller ? "48px" : "64px")};
  }

  @media (max-width: 700px) {
    font-size: ${props => (props.smaller ? "40px" : "56px")};
    margin: 0;
  }

  @media (max-width: 500px) {
    font-size: ${props => (props.smaller ? "32px" : "42px")};
  }
`

const VideoContainer = styled.div`
  padding: 0 0 0 0;
  position: relative;
  height: ${props => `calc((100vw - 100px) * (${props.height} / 100))`};

  @media (max-width: 700px) {
    height: ${props => `calc((100vw - 40px) * (${props.height} / 100))`};
  }
`

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;

  @media (max-width: 700px) {
    flex-direction: column;
    margin: 30px 0;
  }
`

const ProjectBanner = ({ title, videoLink, height, credits }) => {
  return (
    <Container>
      <VideoContainer height={parseFloat(height)}>
        <iframe
          src={videoLink}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title={"video"}
        ></iframe>
      </VideoContainer>
      <script src="https://player.vimeo.com/api/player.js"></script>
      <InfoContainer>
        <Title
          smaller={title === "Holt Renfrew x Uninterrupted - What Do You Wear?"}
          noMargin={title === "ANDRAS 21"}
        >
          {title}
        </Title>
        <ProjectCredits credits={credits} />
      </InfoContainer>
    </Container>
  )
}

export default ProjectBanner
