import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  margin: 150px auto 0 auto;
  @media (max-width: 700px) {
    width: calc(100% - 40px);
    margin: 120px auto 0 auto;
  }
`

const Title = styled.h1`
  font-family: "Bebas Neue";
  font-weight: 900;
  font-size: 96px;
  color: white;
  margin: 0 0 20px 0;
  text-transform: none;
  @media (max-width: 700px) {
    font-size: 38px;
    margin: 0 0 10px 0;
  }
`

const VideoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 0.5871fr 0.4129fr;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

const VideoContainer = styled.div`
  padding: 0 0 0 0;
  position: relative;
  height: ${props =>
    `calc(((100vw - 110px) * ${props.width}) * (${props.height} / 100))`};

  @media (max-width: 1000px) {
    height: ${props => `calc((100vw - 100px) * (${props.height} / 100))`};
  }

  @media (max-width: 700px) {
    height: ${props => `calc((100vw - 40px) * (${props.height} / 100))`};
  }
`

const AltGalleryTwo = ({ title, largeVideos, smallVideos }) => {
  // hard coded for 6
  const videosArray = [
    largeVideos[0],
    smallVideos[0],
    largeVideos[1],
    smallVideos[1],
    largeVideos[2],
    smallVideos[2],
  ]

  console.log("hello")
  console.log(videosArray)

  return (
    <Container>
      <Title>{title}</Title>
      <VideoGrid>
        {videosArray &&
          videosArray.map((node, i) => {
            return (
              <VideoContainer
                height={i === 0 || i === 2 || i === 4 ? 125 : 177.78}
                width={i === 0 || i === 2 || i === 4 ? 0.5871 : 0.4129}
              >
                <iframe
                  src={node.video}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title={"video"}
                ></iframe>
              </VideoContainer>
            )
          })}
      </VideoGrid>
      {/* <VideoGrid>
        {videos &&
          videos.map(node => {
            return (
              <VideoContainer height={56.25}>
                <iframe
                  src={node.video}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title={"video"}
                ></iframe>
              </VideoContainer>
            )
          })}
      </VideoGrid> */}
      <script src="https://player.vimeo.com/api/player.js"></script>
    </Container>
  )
}

export default AltGalleryTwo
