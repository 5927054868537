import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import ALogo from "../images/andras-updated.gif"
import { TransitionContext } from "../contexts/transitionContext"
import { gsap } from "gsap"

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px 0 50px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  position: fixed;
  top: 30px;
  left: 0;
  opacity: 0;

  @media (max-width: 700px) {
    padding: 0 20px 0 20px;
    top: 20px;
  }
`

const Logo = styled.img`
  width: 70px;
  cursor: pointer;
  z-index: 5;

  @media (max-width: 700px) {
    width: 50px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: ${props =>
    props.position === "left" ? "flex-start" : "flex-end"};
`

const TopbarText = styled.p`
  text-decoration: none;
  color: white;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  cursor: pointer;

  @media (max-width: 700px) {
    font-size: 16px;
  }
`

const Topbar = ({ path }) => {
  const { handleForwardLink, handleBackLink } = useContext(TransitionContext)

  useEffect(() => {
    if (path != "/") {
      gsap.set("#topbar", { autoAlpha: 1 })
    }
  }, [])

  const projectClickHandler = () => {
    if (path !== "/projects/" && path !== "/projects" && path !== "/") {
      if (path !== "/info/" && path !== "/info" && path !== "/") {
        handleBackLink(".projectOverlay", "/projects", "project")
      } else {
        handleBackLink("#infoOverlay", "/projects", "info")
      }
    } else {
      navigate("/projects")
    }
  }

  const infoClickHandler = () => {
    if (path !== "/info/" && path !== "/info" && path !== "/") {
      handleForwardLink(".infoOverlay", "/info")
    } else {
      navigate("/info")
    }
  }

  return (
    <Container id="topbar">
      <LinkWrapper position="left">
        <TopbarText onClick={projectClickHandler}>PROJECTS</TopbarText>
      </LinkWrapper>
      <Logo onClick={projectClickHandler} src={ALogo}></Logo>
      <LinkWrapper position="right">
        <TopbarText onClick={infoClickHandler}>INFO</TopbarText>
      </LinkWrapper>
    </Container>
  )
}

export default Topbar
