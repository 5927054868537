import React, { useContext, useEffect } from "react"
import { createGlobalStyle } from "styled-components"
import { LoadingContext } from "../contexts/loadingContext"

const GlobalStyle = createGlobalStyle`
  body {
    position: ${props => (props.visible ? "fixed" : "inherit")};
  }
`
const GlobalStyleComponent = () => {
  const { disableScroll } = useContext(LoadingContext)

  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    const handleResize = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return <GlobalStyle visible={disableScroll} />
}

export default GlobalStyleComponent
