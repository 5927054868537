import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { LoadingContext } from "../contexts/loadingContext"
import LoadingAnimation from "../images/loading-animation.gif"

const LoaderContainer = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: black;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  pointer-events: ${props => (props.visible ? "auto" : "none")};
  opacity: ${props => (props.visible ? 1 : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ease-in-out 0.5s;
`

const LoaderGif = styled.img`
  width: 500px;

  @media (max-width: 800px) {
    width: 500px;
  }

  @media (max-width: 500px) {
    width: 350px;
  }

  @media (max-width: 350px) {
    width: 300px;
  }
`

const Loader = ({ path }) => {
  const { loaderVisible, completeLandingAnimation } = useContext(LoadingContext)

  useEffect(() => {
    if (path !== "/") {
      setTimeout(() => {
        completeLandingAnimation()
      }, 2000)
    } else if (path === "/") {
      setTimeout(() => {
        completeLandingAnimation()
      }, 20000)
    }
  }, [])

  return (
    <LoaderContainer visible={loaderVisible}>
      <LoaderGif src={LoadingAnimation}></LoaderGif>
    </LoaderContainer>
  )
}

export default Loader
