import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const FullWidthGif = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

function renderGif(file) {
  return <FullWidthGif src={file.node.publicURL} />
}

const Gif = ({ gifRef, gifPath }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gifs: allFile(filter: { extension: { regex: "/gif/" } }) {
            edges {
              node {
                extension
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={({ gifs }) =>
        renderGif(
          gifs.edges.find(
            gif => gif.node.relativePath === `projects/${gifRef}/${gifPath}`
          )
        )
      }
    />
  )
}

export default Gif
