import React from "react"
import styled from "styled-components"
import Image from "../image.js"
import Video from "../video.js"
import Gif from "../gif.js"

const Gallery = styled.div`
  width: calc(100% - 100px);
  margin: 0 auto 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  @media (max-width: 1000px) {
    margin: 0 auto 0 auto;
    grid-template-columns: 1fr;
  }

  @media (max-width: 700px) {
    width: calc(100% - 40px);
  }
`

const ContentWrapper = styled.div`
  width: 100%;
`

const HalfContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

const GridContentWrapperLeft = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "small1 small2 large large"
    "medium medium large large";
  grid-gap: 10px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "large large"
      "medium small1"
      "medium small2";
  }
`

const GridContentWrapperRight = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "large large small1 small2"
    "large large medium medium";
  grid-gap: 10px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "medium medium"
      "small1 large"
      "small2 large";
  }
`

const GridContent = styled.div`
  width: 100%;
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea}`}
`

const ProjectGallery = ({ content, imageRef }) => {
  let ogProjectContent = content ? content : []
  let i = 0
  let projectContent = []
  let contentRef = imageRef

  while (i < ogProjectContent.length) {
    if (ogProjectContent[i].gridArea === "full") {
      projectContent.push(
        <ContentWrapper key={`${contentRef}-${i}`}>
          {ogProjectContent[i].contentType === "image" ? (
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i].contentPath}
            />
          ) : ogProjectContent[i].contentType === "video" ? (
            <Video
              videoRef={contentRef}
              videoPath={ogProjectContent[i].contentPath}
            />
          ) : (
            <Gif
              gifRef={contentRef}
              gifPath={ogProjectContent[i].contentPath}
            />
          )}
        </ContentWrapper>
      )
      i += 1
    } else if (ogProjectContent[i].gridArea === "half") {
      projectContent.push(
        <HalfContentWrapper key={`${contentRef}-${i}`}>
          <Image
            alt="filler"
            imageRef={contentRef}
            imagePath={ogProjectContent[i].contentPath}
          />
          <Image
            alt="filler"
            imageRef={contentRef}
            imagePath={ogProjectContent[i + 1].contentPath}
          />
        </HalfContentWrapper>
      )
      i += 2
    } else if (ogProjectContent[i].gridArea === "small1") {
      projectContent.push(
        <GridContentWrapperLeft key={`${contentRef}-${i}`}>
          <GridContent gridArea={ogProjectContent[i].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i].contentPath}
            />
          </GridContent>
          <GridContent gridArea={ogProjectContent[i + 1].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i + 1].contentPath}
            />
          </GridContent>
          <GridContent gridArea={ogProjectContent[i + 2].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i + 2].contentPath}
            />
          </GridContent>
          <GridContent gridArea={ogProjectContent[i + 3].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i + 3].contentPath}
            />
          </GridContent>
        </GridContentWrapperLeft>
      )
      i += 4
    } else if (ogProjectContent[i].gridArea === "large") {
      projectContent.push(
        <GridContentWrapperRight key={`${contentRef}-${i}`}>
          <GridContent gridArea={ogProjectContent[i].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i].contentPath}
            />
          </GridContent>
          <GridContent gridArea={ogProjectContent[i + 1].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i + 1].contentPath}
            />
          </GridContent>
          <GridContent gridArea={ogProjectContent[i + 2].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i + 2].contentPath}
            />
          </GridContent>
          <GridContent gridArea={ogProjectContent[i + 3].gridArea}>
            <Image
              alt="filler"
              imageRef={contentRef}
              imagePath={ogProjectContent[i + 3].contentPath}
            />
          </GridContent>
        </GridContentWrapperRight>
      )
      i += 4
    } else {
      break
    }
  }
  return <Gallery>{projectContent}</Gallery>
}

export default ProjectGallery
